import React from "react";
import logo from "../assets/icons/nazai-logo.png";
function Footer() {
  return (
    <div id="contact-us" className="mt-10 w-screen">
      <div className="w-screen bg-black py-10">
        <div className="container mx-auto flex md:flex-row flex-col justify-center items-center">
          <div className="md:w-1/3 w-full">
            <a href="/">
              <img alt="" className="md:mx-0 mx-auto my-4" src={logo} />
            </a>
          </div>
          <div className="md:w-1/3 w-full  text-white">
            <div className="mx-1 my-1 font-bold md:text-left text-center">
              Nos horaires
            </div>
            <div className="mx-1 my-1 text-sm font-light md:text-left text-center">
              Lundi – Samedi: 9h à 21h
            </div>
            <div className="mx-1 mt-5 mb-1  font-bold md:text-left text-center">
              Notre adresse
            </div>
            <div className="mx-1 my-1 text-sm font-light md:text-left text-center">
              Magasin 1 Résidence Taoufik – Semlalia Marrakech
            </div>
          </div>
          <div className="md:w-1/3 w-full text-white">
            <div className="mx-1 my-1  font-bold md:text-left text-center">
              Numéro de téléphone
            </div>
            <div className="mx-1 my-1 text-sm font-light md:text-left text-center ">
              +212 7 07 01 30 13
            </div>
            <div className="mx-1 mt-5 mb-1  font-bold md:text-left text-center">
              Adresse e-mail
            </div>
            <div className="mx-1 my-1 text-sm font-light md:text-left text-center">
              hello@nazaistudio.ma
            </div>
          </div>
        </div>
      </div>

      <div className="w-screen bg-[#DA0E26]">
        <div className="contaner text-sm text-center mx-auto py-4 text-white ">
          2023 By{" "}
          <a
            className="font-bold"
            href="https://aynnaka.ma/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Aynnaka
          </a>{" "}
          , All Right Reserved
        </div>
      </div>
    </div>
  );
}

export default Footer;
