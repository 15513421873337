import courseOne from "./assets/images/course-one.png";
import courseTwo from "./assets/images/course-two.png";
import courseThree from "./assets/images/course-three.png";

export const listCours = [
  {
    title: "Cours privé",
    description:
      "Bénéficiez d’une séance privée et 100% personnalisée selon votre niveau et besoin.",
    image: courseOne,
  },
  {
    title: "Cours EN groupE",
    description:
      "Passez du temps avec vos amis tout en vous mettant en forme avec notre cours de groupe de boxe chez Nazaï.",
    image: courseTwo,
  },
  {
    title: "Cours DUO",
    description:
      "Partagez une expérience de boxe unique avec un ami ou votre partenaire grâce à notre cours duo privé .",
    image: courseThree,
  },
];
