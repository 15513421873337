import React, { useEffect, useState } from "react";
import logo from "../assets/icons/nazai-logo.png";

function Header() {
  const [small, setSmall] = useState(false);
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setSmall(window.pageYOffset > 200)
      );
    }
  }, []);
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div
      className={`w-full fixed top-0 py-1 z-40 transition-all duration-1000 ${
        small ? "bg-black shadow-md " : ""
      }`}
    >
      <div className="container mx-auto flex justify-between items-center">
        {/* logo */}
        <a href="/">
          <img className="h-20 md:mx-1 mx-3" alt="nazai studio" src={logo} />
        </a>

        {/* menu */}
        <div className="md:flex hidden flex-row items-center justify-center">
          <a className="mx-3 hover:text-[#DA0E26] text-white" href="/#about-us">
            <div>À propos</div>
          </a>
          <a className="mx-3 hover:text-[#DA0E26] text-white" href="/#cours">
            <div>Nos cours</div>
          </a>
          <a className="mx-3 hover:text-[#DA0E26] text-white" href="/#coach">
            <div>Notre coach</div>
          </a>
          <a
            className="mx-3 hover:text-[#DA0E26] text-white"
            href="/#testemonials"
          >
            <div>Testemonials</div>
          </a>
          <a className="mx-3 hover:text-[#DA0E26] text-white" href="/#reserve">
            <div>Réserver</div>
          </a>
        </div>
        {/* contact */}
        <div className="md:block hidden">
          <a href="/#contact-us">
            <div className="text-white bg-[#DA0E26] rounded-full px-4 py-3">
              Contactez-nous
            </div>
          </a>
        </div>
        <div
          onClick={() => setIsOpen(true)}
          className="md:hidden block text-[#DA0E26] cursor-pointer mx-10"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            className="w-9 h-9"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 0 1 0 3.75H5.625a1.875 1.875 0 0 1 0-3.75Z"
            />
          </svg>
        </div>

        {/*  */}
        <div
          className={`bg-black overflow-hidden bg-opacity-90 fixed z-50 top-0  h-screen right-0 duration-500 transition-all ${
            isOpen ? "w-screen" : "w-0"
          }`}
        >
          <div className="w-full flex justify-between px-3">
            <svg
              onClick={() => setIsOpen(!isOpen)}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-10 h-10 text-white m-5 cursor-pointer hover:text-[#DA0E26]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
            <a onClick={() => setIsOpen(!isOpen)} href="/">
              <img className="h-20" alt="nazai studio" src={logo} />
            </a>
          </div>
          <div className="w-full my-8 flex flex-col items-center">
            <a
              onClick={() => setIsOpen(!isOpen)}
              className="mx-3 my-3 text-md hover:text-[#DA0E26] text-white"
              href="/#about-us"
            >
              <div>À propos</div>
            </a>
            <a
              onClick={() => setIsOpen(!isOpen)}
              className="mx-3 my-3 text-md hover:text-[#DA0E26] text-white"
              href="/#cours"
            >
              <div>Nos cours</div>
            </a>
            <a
              onClick={() => setIsOpen(!isOpen)}
              className="mx-3 my-3 text-md hover:text-[#DA0E26] text-white"
              href="/#coach"
            >
              <div>Notre coach</div>
            </a>
            <a
              onClick={() => setIsOpen(!isOpen)}
              className="mx-3 my-3 text-md hover:text-[#DA0E26] text-white"
              href="/#testemonials"
            >
              <div>Testemonials</div>
            </a>
            <a
              onClick={() => setIsOpen(!isOpen)}
              className="mx-3 my-3 text-md hover:text-[#DA0E26] text-white"
              href="/#reserve"
            >
              <div>Réserver</div>
            </a>
            <a
              onClick={() => setIsOpen(!isOpen)}
              className="mx-3 my-3 text-md hover:text-[#DA0E26] text-white"
              href="/#contact-us"
            >
              <div>Contact</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
