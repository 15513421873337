import React, { useState } from "react";

import background from "../assets/images/background-nazai.png";
import backgroundCourse from "../assets/images/cours-background.png";
import calque from "../assets/images/Calque 2.png";
import calqueB from "../assets/images/Calque 3.png";

import arrowLeft from "../assets/icons/arrow-left.png";
import arrowRight from "../assets/icons/arrow-rigth.png";

import Header from "../components/Header";
import boxingIcon from "../assets/icons/boxing-icon.png";
import boxMilkIcon from "../assets/icons/box-milk-icon.png";
import watchIcom from "../assets/icons/watch-icon.png";
import luckIcon from "../assets/icons/lock-icon.png";

import aboutImage from "../assets/images/about-img.png";
import coachImage from "../assets/images/coach-image.png";

import testa from "../assets/images/ellipse-a.png";
import reserveImage from "../assets/images/reserve-image.png";
import Footer from "../components/Footer";
import { listCours } from "../constants";

function HomeScreen() {
  const [index, setIndex] = useState(0);
  return (
    <div className="w-full h-full">
      {/* header */}
      <Header />
      {/*  */}
      <div
        className="w-full min-h-[90vh] bg-cover bg-no-repeat relative "
        style={{
          background: "url(" + background + ")",
          backgroundPosition: "top",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="container mx-auto min-h-[90vh] w-screen flex items-center relative">
          <div className="text-white lg:w-1/2 md:w-2/3  w-full ">
            <div className="md:mx-1 mx-3">
              <div className="uppercase font-bold md:text-6xl text-4xl text-white ">
                Un corps<span className="text-[#DA0E26]"> plus fort </span>
                et un esprit <span className="text-[#DA0E26]"> plus sain</span>
              </div>
              {/*  */}
              <div className="my-5 w-max mx-10 mb-10">
                <a
                  href="https://www.momoyoga.com/nazaistudio/schedule"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className=" relative text-white px-5 py-3  inset-0 bg-[#DA0E26] transform -skew-x-[50deg]  w-full">
                    <div className=" inset-0  skew-x-[50deg]  flex items-center justify-center">
                      <p className=" text-white font-bold text-md px-5">
                        Réservez votre séance
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute md:-bottom-10 -bottom-12 w-full">
          <div className="container px-5 mx-auto">
            <div className="flex md:flex-row flex-wrap px-5 py-3 justify-between items-center bg-black bg-opacity-95 shadow-2xl rounded-xl shadow-[#0025]">
              <div className=" text-white flex flex-col justify-center items-center md:w-max w-1/2 md:my-0 my-2">
                <img className="md:w-10 w-5 my-2" alt="icon" src={boxingIcon} />
                <p className="md:text-sm text-xs">Coach qualifié</p>
              </div>
              <div className=" text-white flex flex-col justify-center items-center  md:w-max w-1/2 md:my-0 my-2">
                <img className="md:w-10 w-5 my-2" alt="icon" src={luckIcon} />
                <p className="md:text-sm text-xs">Séances privées</p>
              </div>
              <div className=" text-white flex flex-col justify-center items-center  md:w-max w-1/2 md:my-0 my-2">
                <img
                  className="md:w-10 w-5 my-2"
                  alt="icon"
                  src={boxMilkIcon}
                />
                <p className="md:text-sm text-xs">Shakes & snacks</p>
              </div>
              <div className=" text-white flex flex-col justify-center items-center  md:w-max w-1/2 md:my-0 my-2">
                <img className="md:w-10 w-5 my-2" alt="icon" src={watchIcom} />
                <p className="md:text-sm text-xs">Horaire flexible</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div id="about-us" className=" md:h-24 h-16"></div>
      {/* About */}
      <div className="container mx-auto py-5 px-3 flex md:flex-row flex-col md:mt-20 mt-10">
        <div className="md:w-1/2 w-full">
          <div className="flex md:justify-start justify-center">
            <img
              className=" md:w-1/2 w-2/3 md:mx-0 mx-auto my-5"
              alt="about"
              src={aboutImage}
            />
          </div>
        </div>
        <div className="md:w-1/2 w-full relative ">
          <div className="relative md:mx-0 mx-2 ">
            <div className=" uppercase font-bold md:text-6xl text-5xl text-black">
              Bienvenue chez{" "}
              <span className="text-[#DA0E26]">Nazaï STUDIO</span>
            </div>
            <div class="w-full absolute md:-top-1/3 -top-4">
              <span className="text-black text-opacity-5 md:text-8xl text-6xl font-bold font-['Poppins'] uppercase ">
                Bienvenue chez{" "}
              </span>
              <span className="text-red-600 text-opacity-25 md:text-8xl text-7xl font-bold font-['Poppins'] uppercase ">
                nazaï
              </span>
            </div>
          </div>
          <div className="mt-10 mb-2 md:text-2xl text-xl text-opacity-60 text-black md:mx-0 mx-2">
            Nazaï studio est l'endroit idéal pour les personnes passionnées par
            la boxe et les sports de combat. Nous proposons des cours adaptés à
            tous les niveaux, du débutant à l'expert. Nous croyons que la boxe
            est un excellent moyen de se maintenir en forme, de développer sa
            force et sa confiance en soi. Nous sommes fiers de notre communauté
            de boxeurs passionnés et de nos instructeurs qualifiés.
          </div>
        </div>
      </div>
      {/* coach */}
      <div id="coach"></div>
      <div className="container mx-auto py-5 px-3 flex md:flex-row flex-col-reverse md:mt-24 mt-14">
        <div className="md:w-1/2 w-full relative">
          <div className="relative md:mx-0 mx-3">
            <div className=" uppercase font-bold md:text-5xl text-4xl text-black">
              <span className="text-[#DA0E26]">Rencontrez Khalid</span>, notre
              coach passionné
            </div>
            <div class=" absolute md:-top-10 -top-5  md:text-8xl text-6xl  font-bold  uppercase">
              <span className="text-black text-opacity-5  ">Rencontrez </span>
              <span className="text-red-600 text-opacity-25  ">Khalid</span>
            </div>
          </div>
          <div className="mt-10 mb-2 md:text-2xl text-xl  text-opacity-60 text-black md:mx-0 mx-2">
            Rencontrez Khalid, notre coach de boxe passionné et natif de
            Marrakech. Après avoir remporté des tournois locaux et nationaux,
            Khalid a obtenu plusieurs diplômes de coaching en boxe, kickboxing,
            Muay Thai et Full Contact. Chez nous, il enseigne et partage sa
            passion pour la boxe avec des passionnés et des amateurs de tous
            niveaux. Rejoignez-nous pour une séance d'entraînement de boxe
            énergisante et amusante avec Khalid .
          </div>
        </div>
        <div className="md:w-1/2 w-full mb-5">
          <div className="flex md:justify-end justify-center">
            <img
              className=" md:w-1/2 w-2/3 md:mx-0 mx-auto my-5"
              alt="about"
              src={coachImage}
            />
          </div>
        </div>
      </div>
      {/* course */}
      <div id="cours"></div>
      <div
        className="w-full bg-cover bg-no-repeat bg-opacity-35 relative  my-20 md:bg-center "
        style={{
          background: "url(" + background + ")",
          // backgroundPosition: "center",
          backgroundRepeat: "repeat-x",
          // backgroundSize: "contain",
        }}
      >
        <div className="absolute opacity-70 bg-black top-0 left-0 w-full h-full z-0"></div>
        <div className="w-full  z-20 relative">
          <img className="w-full md:max-h-40 max-h-12" src={calque} />
        </div>
        <div className="z-20 relative container mx-auto  px-3 flex md:flex-row  flex-col-reverse my-8 justify-center">
          <div className="md:w-[40%] w-full relative flex flex-col  h-full my-auto md:items-start items-center mx-auto">
            <div className=" uppercase w-max md:text-5xl text-xl font-bold text-white h-max relative z-10">
              <span className="z-10 ">{listCours[index].title}</span>
              <div className="w-full md:h-2 h-1 bg-[#DA0E26] absolute md:top-1/2 top-1/3 -z-10 "></div>
            </div>
            <div className="text-white my-3 md:text-3xl text-md font-normal md:leading-10 md:text-left text-center">
              {listCours[index].description}
            </div>
          </div>
          <div className="md:w-[40%] w-2/3 mx-auto">
            <div className="w-full">
              <div className="flex md:justify-end justify-center">
                <img
                  className=" w-3/5 md:mx-0 mx-auto my-5"
                  alt="about"
                  src={listCours[index].image}
                />
              </div>
            </div>
          </div>
          {/* change course */}
          <div className="absolute right-0 md:top-1/2 top-[40%] z-30 flex justify-center items-center">
            <img
              className=" md:w-10 w-8 md:h-10 h-8 mx-5 cursor-pointer"
              onClick={() => {
                if (listCours.length > 1) {
                  if (index < listCours.length - 1) {
                    setIndex(index + 1);
                  } else {
                    setIndex(0);
                  }
                }
              }}
              alt="change course"
              src={arrowRight}
            />
          </div>
          <div className="absolute left-0 md:top-1/2 top-[40%] z-30 flex justify-center items-center">
            <img
              className=" md:w-10 w-8 md:h-10 h-8 mx-5 cursor-pointer"
              onClick={() => {
                if (listCours.length > 1) {
                  if (index == 0) {
                    setIndex(listCours.length - 1);
                  } else {
                    setIndex(index - 1);
                  }
                }
              }}
              alt="change course"
              src={arrowLeft}
            />
          </div>
        </div>
        <div className="w-full  z-20 relative">
          <img className="w-full md:max-h-40 max-h-12" src={calqueB} />
        </div>
      </div>
      {/*  */}
      <div id="testemonials"></div>
      <div className="container mx-auto my-5 ">
        <div className="w-full text-center font-bold uppercase text-black md:text-3xl text-2xl mt-6 ">
          Ils nous ont fait <span className="text-[#DA0E26]">confiance</span>
        </div>
        <div className="flex flex-row justify-center items-center mt-10">
          <div className="w-max mx-10 justify-center items-center relative">
            <div className="absolute left-0 top-1/2 flex justify-center items-center z-20">
              <img
                className=" md:w-10 w-8 md:h-10 h-8 cursor-pointer"
                alt="change course"
                src={arrowLeft}
              />
            </div>
            <div className="bg-black rounded-md md:w-3/4 w-full flex flex-col items-center justify-center py-10 px-5 mx-auto">
              <img alt="" src={testa} />
              <p className="my-2 text-white font-bold">Manal benchlikha</p>
              <p className="my-3 text-white  w-full text-center md:text-xl text-sm font-light ">
                “J'ai rejoint Nazaï Studio pour des cours de boxe privés et en
                duo, et je suis absolument ravie de mon expérience jusqu'à
                présent. Mon coach Khalid est incroyablement compétent et
                passionné, et il a vraiment réussi à me pousser au-delà de mes
                limites physiques tout en m'aidant à améliorer ma technique de
                boxe.”
              </p>
            </div>
            <div className="absolute right-0 top-1/2 flex justify-center items-center z-20">
              <img
                className=" md:w-10 w-8 md:h-10 h-8 mx-5 cursor-pointer"
                alt="change course"
                src={arrowRight}
              />
            </div>
          </div>
        </div>
      </div>
      {/* reserve */}
      <div id="reserve"></div>

      <div className="container mx-auto py-5 px-3 flex md:flex-row flex-col mt-10 justify-center items-center">
        <div className="md:w-1/2 w-full">
          <div className="flex md:justify-start justify-center">
            <img
              className=" md:w-1/3 w-2/3 md:mx-0 mx-auto my-6"
              alt="about"
              src={reserveImage}
            />
          </div>
        </div>
        <div className="md:w-1/2 w-full relative">
          <div className="relative">
            <div className=" uppercase font-bold md:text-5xl text-3xl md:mx-0 mx-2 text-black">
              Vivez une expérience de{" "}
              <span className="text-[#DA0E26]">boxe privée unique!</span>
            </div>
          </div>
          <div className="mt-10 mb-2 md:text-2xl text-xl text-opacity-60 text-black md:mx-0 mx-2">
            Prêt à relever le défi de la boxe? réservez votre place dès
            maintenant pour une séance d'entraînement privée et personnalisée
            avec notre coach qualifié!
          </div>
          <div className="my-5 w-max md:mx-5 mx-5">
            <a
              href="https://www.momoyoga.com/nazaistudio/schedule"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className=" cursor-pointer relative text-white px-5 py-3  inset-0 bg-black transform -skew-x-[40deg]  w-full">
                <div className=" inset-0  skew-x-[40deg]  flex items-center justify-center">
                  <p className=" text-white font-bold text-lg px-5">Réserver</p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      {/* footer */}
      <Footer />
    </div>
  );
}

export default HomeScreen;
